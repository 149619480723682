<template>
  <div class="account" style="padding-bottom: 100px; background: #fff">
    <top-fixed></top-fixed>
    <div style="margin-top: 0.68rem">
      <div
        class="huodongBg"
        v-for="(item, index) in activityData"
        @click="joinActivitys(item)"
        :key="index"
        style="
          height: 2.07rem;
          margin: 0.3rem 0.25rem;
          border: 1px solid transparent;
          padding: 0 0.3rem;
          background-size: 7rem 2.07rem;
        "
      >
        <div
          style="
            margin-top: 0.3rem;
            display: flex;
            justify-content: space-between;
          "
        >
          <div style="font-size: 15px; font-weight: bold">
            {{ item.act_name }}
          </div>
          <div
            style="
              margin-right: 1.1rem;
              font-size: 15px;
              font-weight: bold;
              color: #ff381d;
            "
          >
            {{ item.status_name }}
          </div>
        </div>
        <div style="margin-top: 0.2rem; font-size: 12px; color: #978f8f">
          {{ item.end_time }}
        </div>

        <div
          style="
            margin-top: 0.4rem;
            display: flex;
            justify-content: space-between;
          "
        >
          <div style="font-size: 16px; font-weight: bold; color: #ff381d">
            {{ item.progress_name }}
          </div>
          <div style="font-size: 11px; color: #978f8f; margin-right: 0.4rem">
            {{ $t("hj21") }}{{ item.awards }}DETH >
          </div>
        </div>
      </div>
    </div>

    <bottom-fixed></bottom-fixed>
  </div>
</template>
  
  
  <script>
import BottomFixed from "../components/BottomFixed.vue";
import TopFixed from "../components/TopFixed.vue";
import { activity, joinActivity } from '../http/api'
export default {
  name: "AccountView",
  data () {
    return {
      activityData: ""
    };
  },
  components: { BottomFixed, TopFixed },
  methods: {
    fetchAtivity () {
      this.$axios.get("/api/mining/activity").then(res => {
        this.ativityList = res.data.data
      })
    },
    dataInit () {
      activity().then((res) => {
        this.activityData = res.data
      })
    },
    joinActivitys (item) {
      if (item.status == '0') {
        joinActivity({ activity_id: item.id }).then((res) => {
          this.$router.push({
            path: "/activitDea",
            query: {
              id: item.id
            }
          })
        })
      } else {
        this.$router.push({
          path: "/activitDea",
          query: {
            id: item.id
          }
        })
      }
    }
  },
  mounted () {
    this.fetchAtivity()
  },
  created () {
    this.dataInit()
  }
};
</script>
  <style scoped>
.huodongBg {
  background-image: url("../assets/img//huodongBg.png");
}
</style>